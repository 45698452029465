<template>
<div>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>卡片名称</span>
      <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
    </div>
    <div v-for="o in listbucket" :key="o" class="text item">
      {{'桶名：' + o.name + '      创建时期:' + o.date}}
    </div>
  </el-card>

</div>
</template>

<script>
export default {
  name: "FileManager",
  data() {
    return {
      listbucket:[],
    }
  },
  mounted(){  //网盘信息

    // 查询bucket列表信息
    this.request.get('/listbucket'
    ).then(res => {

      this.listbucket=res.data

      this.request.get('/listobjects'
      ).then(res => {
        console.log(res)
      })
    })
    // alert( moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
